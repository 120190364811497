import React, { ReactNode, useCallback, useEffect, useState } from "react";
import "./patient-summary.scss";
import { Button, Dropdown, Menu, Modal } from "antd";
import {
  CloseOutlined,
  CopyOutlined,
  DislikeOutlined,
  DownCircleOutlined,
  DownloadOutlined,
  LikeOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import { DiagAndProcCodes, patientSummaryService, uploadFileFolderService } from "../../api";
import { ReactTyped } from "react-typed";
import { MODAL_TYPE, camelToTitleCase, openNotificationWithIcon } from "../../utils";
import { Loading } from "../../components";
import AskAuto from "../../components/ask-auto/AskAuto";
import { CodesPopup } from "./CodesPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  openModal,
  RootState,
  setFeedbackType,
  setPatientsSummary,
  setSummaryId,
  setSummaryType,
} from "../../store";
import { handlePdfDownload, handleDocxDownload, handleCopyToClipboard } from "../../utils";
import { SourcePopup } from "../modals/source-popup/SourcePopup";
import { setCurrentChunkUUID, setCurrentPage as setGlobalCurrentPage } from "../../store/document/slice";
import Markdown from "react-markdown";

export type ProcedureProps = {
  procedureCode?: string;
  procedureDescription?: string;
};

export type DiagnosisProps = {
  diagnosisCode?: string;
  diagnosisDescription?: string;
};

type Props = {
  enableNextTab: (procedureCode: ProcedureProps, diagnosisCode: DiagnosisProps) => void;
  documentUUID: string;
  projectId: number;
  pdf: string;
  fileName: string;
};

interface Procedure {
  procedureCode: string;
  dateOfServiceStart: string;
  dateOfServiceEnd: string;
  procedureDescription: string;
}

interface Diagnosis {
  diagnosisCode: string;
  diagnosisQualifier: string;
  diagnosisDescription: string;
}

interface PatientData {
  acuity: string;
  admissionDateTime: string;
  authorizationID: string;
  dateOfBirth: string;
  dischargeDateTime: string;
  memberID: string;
  memberName: string;
  placeOfService: string;
  requestingProviderName: string;
  requestingProviderFaxNo: string;
  requestingProviderNPI: string;
  servicingProviderName: string;
  servicingProviderNPI: string;
  typeOfService: string;
  state: string;
  procedures: Procedure[];
  diagnoses: Diagnosis[];
  [key: string]: string | any;
}

interface Offset {
  begin: number;
  end: number;
}

interface IPage {
  chunkUUID: string;
  offsets: Offset[];
  pageNo: number;
}

interface ISources {
  category: string;
  documentUUID: string;
  pages: IPage[];
}

const patientDataKeys = Object.keys({
  acuity: "",
  admissionDateTime: "",
  authorizationID: "",
  dateOfBirth: "",
  dischargeDateTime: "",
  memberID: "",
  memberName: "",
  placeOfService: "",
  requestingProviderName: "",
  requestingProviderFaxNo: "",
  requestingProviderNPI: "",
  servicingProviderName: "",
  servicingProviderNPI: "",
  typeOfService: "",
  state: "",
  procedures: [],
  diagnoses: [],
});

export const PatientSummary = ({
  enableNextTab,
  documentUUID,
  projectId,
  pdf,
  fileName,
}: Props) => {
  const [showPatientSummary, setShowPatientSummary] = useState(false);
  const [expandPatientData, setExpandPatientData] = useState(false);
  const [showCodePopup, setShowCodePopup] = useState(false);
  const [selectedProcedureCode, setSelectedProcedureCode] = useState<ProcedureProps>({
    procedureCode: "",
    procedureDescription: "",
  });
  const [selectedDiagnosisCode, setSelectedDiagnosisCode] = useState<DiagnosisProps>({
    diagnosisCode: "",
    diagnosisDescription: "",
  });
  const [patientSummary, setPatientSummary] = useState("");
  const [caseInfo, setCaseInfo] = useState({} as PatientData);
  const [diagAndProcCodes, setDiagAndProcCodes] = useState([] as DiagAndProcCodes[]);
  const [timeToProcess, setTimeToProcess] = useState<number>(0);
  const [extractedFieldCount, setExtractedFieldCount] = useState<number>(0);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const dispatch = useDispatch();
  const [isSummaryGenerated, setIsSummaryGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sources,setSources]= useState<ISources[]>([]);
  const [fileUrl, setFileUrl] = useState("");
  const [chatSource, setChatSource] = useState({} as ISources);
  const {projectDetails} = useSelector((state:RootState)=>state.document)

  const getSourceNumberMap = (sourcesNew: ISources[]) => {
    const map: { [key: string]: { source: ISources; sourceNumber: number, pageNo: number , chunkUUID:string} } = {};
    sourcesNew.forEach((source, index) => {
      source.pages.forEach((page) => {
        map[page.chunkUUID] = { source, sourceNumber: index + 1, pageNo: page.pageNo, chunkUUID:page.chunkUUID };
      });
    });
    return map;
  };

  const handleSourceClick = async (source: ISources, pageNumber?: number,chunkUUID?:string) => {
      setFileUrl("");
      setChatSource(source);
      dispatch(setGlobalCurrentPage(pageNumber || 1));
      dispatch(setCurrentChunkUUID(chunkUUID || ""))
      try {
        const { data } = await uploadFileFolderService.getSignedURLForFullSource(source.documentUUID);
        setFileUrl(data.data.signedUrl);
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
  };
  
  const renderAnswer = useCallback(
    (patientSummaryNew: string, source: ISources[]): ReactNode => {
      const sourceNumberMap = getSourceNumberMap(source);
      const uuidRegex = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g;
  
      const parts = patientSummaryNew.split(uuidRegex).map(part => {
        const trimmedPart = part?.replace(/[,[\].]| {2}/g, ' ').trim();
        return(
          trimmedPart
        )}
        );
      const chunkUUIDs = patientSummaryNew.match(uuidRegex) || [];
  
      const processedData = parts.map((part, index) => {
        const uuid = chunkUUIDs[index];
        const citation = uuid && sourceNumberMap[uuid] 
          ? `[${sourceNumberMap[uuid].sourceNumber}]` 
          : ''; 
        return (
          <span key={index}>
            <Markdown>{part}</Markdown>    
            {citation && (
            <span                
              className="citation-number"
              onClick={() => handleSourceClick(sourceNumberMap[uuid].source, sourceNumberMap[uuid].pageNo,sourceNumberMap[uuid].chunkUUID)}
            >
              {citation}
            </span>
            )}    
          </span> 
        );
      });
      return <div>{processedData}</div>;
    },
    [sources, patientSummary]
  );
  

  const getCaseInfo = async () => {
    setIsLoading(true);
    try {
      const { data } = await patientSummaryService.getCaseInfo(documentUUID);
      setCaseInfo(data.data.KeyValueOutput);
      setDiagAndProcCodes(data.data.diagAndProcCodes);
      setTimeToProcess(data.data.timeToProcess);
      setExtractedFieldCount(data.data.extractedFieldCount);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCaseInfo();
  }, []);

  const handleClose = () => {
    setShowCodePopup(false);
  };

  const handleConfirmCode = async (diagnoses?: DiagnosisProps, procedure?: ProcedureProps) => {
    if (isLoading) return;
    try {
      handleClose();
      setIsSummaryLoading(true);
      setIsLoading(true)
      const { data } = await patientSummaryService.generatePatientSummary(
        documentUUID,
        diagnoses ? diagnoses : selectedDiagnosisCode,
        procedure ? procedure : selectedProcedureCode
      );
      setPatientSummary(data.data.summary || data.data.message || "Summary not found");
      setSources(data?.data?.sources)
      dispatch(setPatientsSummary(data?.data?.summary));
      dispatch(setSummaryId(data?.data?.summaryId));
      setShowPatientSummary(true);
      setExpandPatientData(false);
      setIsSummaryGenerated(true);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally{
      setIsLoading(false)
      setIsSummaryLoading(false);

    }
  };

  const handleGeneratePatientSummary = () => {
    if (isLoading) return; 
    dispatch(setSummaryType("Patient Summary"));
    if (diagAndProcCodes.length > 2) {
      setShowCodePopup(true);
    } else if (diagAndProcCodes.length === 2) {
      const splitCodes = diagAndProcCodes.reduce(
        (acc: any, code) => {
          if (code.diagnosisCode) {
            acc.diagnosisCodes.push({
              diagnosisCode: code.diagnosisCode,
              diagnosisDescription: code.diagnosisDescription,
            });
          } else if (code.procedureCode) {
            acc.procedureCodes.push({
              procedureCode: code.procedureCode,
              procedureDescription: code.procedureDescription,
            });
          }
          return acc;
        },
        { diagnosisCodes: [], procedureCodes: [] }
      );
      const { diagnosisCodes, procedureCodes } = splitCodes;
      setSelectedProcedureCode({
        procedureCode: procedureCodes[0].procedureCode,
        procedureDescription: procedureCodes[0].procedureDescription,
      });
      setSelectedDiagnosisCode({
        diagnosisCode: diagnosisCodes[0].diagnosisCode,
        diagnosisDescription: diagnosisCodes[0].diagnosisDescription,
      });
      handleConfirmCode(
        {
          diagnosisCode: diagnosisCodes[0].diagnosisCode,
          diagnosisDescription: diagnosisCodes[0].diagnosisDescription,
        },
        {
          procedureCode: procedureCodes[0].procedureCode,
          procedureDescription: procedureCodes[0].procedureDescription,
        }
      );
    }
  };

  const handleGenerateCompliance = () => {
    enableNextTab(selectedProcedureCode, selectedDiagnosisCode);
    dispatch(setSummaryType("Guidelines Summary"));
  };

  const formatKey = (key: string) => {
    const words = key.split(/(?=[A-Z])/);
    const formattedKey = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return formattedKey;
  };

  const downloadPDF = async () => {
    try {
      const response = await fetch(pdf);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      openNotificationWithIcon("", "Downlaod Failed", "error");
    }
  };

  const handleThumbsClick = (type: "up" | "down") => {
    // dispatch(
    //   setQuestionAnswerLogId({
    //     logId: item.logId,
    //     qaId: item.qaId,
    //   })
    // );
    dispatch(setFeedbackType(type));
    dispatch(openModal(MODAL_TYPE.FEEDBACK));
  };

  const exportMenu = (
    <Menu>
      <Menu.Item
        key="pdf"
        icon={<DownloadOutlined />}
        className="export-dropdown-item"
        onClick={() => handlePdfDownload(patientSummary, "Patient Summary", fileName)}
      >
        Download as PDF
      </Menu.Item>
      <Menu.Item
        key="docx"
        icon={<DownloadOutlined />}
        className="export-dropdown-item"
        onClick={() => handleDocxDownload(patientSummary, "Patient Summary", fileName)}
      >
        Download as Docx
      </Menu.Item>
      <Menu.Item
        key="copy"
        icon={<CopyOutlined />}
        className="export-dropdown-item"
        onClick={() => handleCopyToClipboard(patientSummary, "Patient Summary")}
      >
        Copy to Clipboard
      </Menu.Item>
    </Menu>
  );
 
  const renderNotExpandedPatientData = () => (
    <>
      {!expandPatientData && Object.keys(caseInfo).length > 0 && (
        <div className="single-info grid grid-cols-2">
          <div className="patientData-info">{formatKey(Object.keys(caseInfo)[0])}</div>
          { 
          <div className="fz-16 p-block">
          {Array.isArray(caseInfo[Object.keys(caseInfo)[0]])
            ? caseInfo[Object.keys(caseInfo)[0]][0]?.procedureCode 
            : caseInfo[Object.keys(caseInfo)[0]]}
        </div>
          }
        </div>
      )}
    </>
  );

  const renderExpandedPatientData = () => (
    <>
      {expandPatientData && (
        <>
          {caseInfo &&
            Object.entries(caseInfo)
              .filter(([key]) => patientDataKeys.includes(key))
              .map(([key, value], index) => {
                return (
                  <div key={index}>
                    {key !== "procedures" && key !== "diagnoses" && (
                      <div className="single-info grid grid-cols-2">
                        <div className="patientData-info">{formatKey(key)}</div>
                        <div className="fz-16 p-block">{value}</div>
                      </div>
                    )}
                    {key === "procedures" && (
                      <>
                        {value.map((procedure: Procedure, procedureIndex: number) => (
                          <div
                            key={`procedure-${procedureIndex}`}
                            className="single-info grid grid-cols-2"
                          >
                            <div className="patientData-info">Procedure {procedureIndex + 1}</div>
                            <div className="fz-16 p-block">
                              {procedure?.procedureCode && (
                                <div>{`Procedure Code: ${procedure?.procedureCode}`}</div>
                              )}
                              {procedure?.dateOfServiceStart && (
                                <div>{`Date of Service Start: ${procedure?.dateOfServiceStart}`}</div>
                              )}
                              {procedure?.dateOfServiceEnd && (
                                <div>{`Date of Service End: ${procedure?.dateOfServiceEnd}`}</div>
                              )}
                              {procedure?.procedureDescription && (
                                <div>{`Procedure Description: ${procedure?.procedureDescription}`}</div>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                    {key === "diagnoses" && (
                      <>
                        {value.map((diagnosis: Diagnosis, diagnosisIndex: number) => (
                          <div
                            key={`diagnosis-${diagnosisIndex}`}
                            className="single-info grid grid-cols-2"
                          >
                            <div className="patientData-info">Diagnosis {diagnosisIndex + 1}</div>
                            <div className="fz-16 p-block">
                              {diagnosis.diagnosisCode && (
                                <div>{`Diagnosis Code: ${diagnosis.diagnosisCode}`}</div>
                              )}
                              {diagnosis.diagnosisQualifier && (
                                <div>{`Diagnosis Qualifier: ${diagnosis.diagnosisQualifier}`}</div>
                              )}
                              {diagnosis.diagnosisDescription &&
                                !diagnosis.diagnosisDescription.includes("unspecified") && (
                                  <div>{`Diagnosis Description: ${diagnosis.diagnosisDescription}`}</div>
                                )}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                );
              })}
        </>
      )}
    </>
  );

  const renderMedicalHistorySources = (): ReactNode => {
    return (
      <div className="source-container">
        <h5>Sources: </h5>
        <div className="source-list flex gp-10">
          {!!sources?.length &&
            sources?.map((source, i) =>{
              return(
                <li className="cursor-pointer" key={i} onClick={() => handleSourceClick(source)}>
                  <a>{source?.category}[1]</a>
                </li>
              )  
            } )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="patientData">
        <div className="patientData-info">
          {`Time to process: ${(
            timeToProcess / 1000
          ).toFixed()} secs  |  Extracted fields count: ${ extractedFieldCount==0 ? Object.keys(caseInfo).length :  extractedFieldCount}`}
        </div>
        <div
          className={`patientData-patientInfo  ${
            patientSummary
              ? `patientData-patientInfo-${expandPatientData ? "expanded" : "short"}`
              : "show-full"
          }`}
        >
          {renderNotExpandedPatientData()}

          {renderExpandedPatientData()}
        </div>

        {!expandPatientData ? (
          <div className="patientData-downicon">
            <DownCircleOutlined
              onClick={() => {
                setExpandPatientData(!expandPatientData);
              }}
            />
          </div>
        ) : (
          <div className="patientData-upicon">
            <UpCircleOutlined
              onClick={() => {
                setExpandPatientData(!expandPatientData);
              }}
            />
          </div>
        )}
        {showPatientSummary ? (
          isSummaryLoading ? (
            <Loading />
          ) : (
            <div className="patientData-patientSummary">
              <h2 className="patientData-info">Patient Summary</h2>
             {sources?.length==0 ||  sources?.length==undefined? (<ReactTyped
                strings={[patientSummary.replace(/&/g, "&amp;")]}
                typeSpeed={1}
                showCursor={false}
                style={{ fontSize: "16px" }}
              /> ):
              ( <>
                <div className="row">
                  <div className="value">{renderAnswer(patientSummary, sources)}</div>
                </div>
                {renderMedicalHistorySources()}
                {!!Object.keys(sources).length && (
                    <SourcePopup
                      chatSource={chatSource}
                      setChatSource={setChatSource}
                      fileUrl={fileUrl}
                      guideline={projectDetails.guidelines}
                    />
                )}
                </>)}
                
            </div>
          )
        ) : (
          <div className="patientData-actions">
            <div className="actions">
              {isSummaryGenerated && (
                <>
                  <AskAuto projectId={projectId} documentUuid={documentUUID} />
                  <LikeOutlined
                    className="cursor-pointer flex ask-auto-button"
                    onClick={() => handleThumbsClick("up")}
                  />
                  <DislikeOutlined
                    className="cursor-pointer flex ask-auto-button"
                    onClick={() => handleThumbsClick("down")}
                  />
                </>
              )}
            </div>
           {isLoading ? <Loading /> : <Button
              className="fill"
              style={{ float: "right" }}
              onClick={handleGeneratePatientSummary}
              disabled={isLoading}
            >
              Generate Patient Summary
            </Button>}
          </div>
        )}

        {showPatientSummary && (
          <div className="patientData-footer">
            <div className="actions">
              <AskAuto buttonClassName="action" documentUuid={documentUUID} projectId={projectId} />
              <LikeOutlined
                className="cursor-pointer flex action"
                onClick={() => handleThumbsClick("up")}
              />
              <DislikeOutlined
                className="cursor-pointer flex ask-auto-button"
                onClick={() => handleThumbsClick("down")}
              />
            </div>
            <div className="flex gp">
              <Dropdown overlay={exportMenu} placement="topCenter" trigger={["click"]}>
                <Button className="outline">Export Summary as</Button>
              </Dropdown>
              <Button className="fill" onClick={handleGenerateCompliance}>
                Generate Guideline Compliance
              </Button>
            </div>
          </div>
        )}
      </div>
      {showCodePopup && !isLoading && (
          <CodesPopup
            {...{
              showCodePopup,
              setShowCodePopup,
              diagAndProcCodes,
              setSelectedProcedureCode,
              setSelectedDiagnosisCode,
              handleConfirmCode,
            }}
          />
        )}
      
    </>
  );
};

export default PatientSummary;
